.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
font-family: 'AlegreyaSans-Regular';
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.carousel__image{
    text-align: center;
}
.base__body{
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 15px;
}
.imgcolor,
.img,
.uploadimges{
    width: 100px;
    height: 100px;
    margin-left: 5px;
}
.fileImg {
    display: none;
}
.imggg{
    height: 100%;
    width: 100%;
}
.delete__body{
    position: relative;
}
.delete__icon{
width: 30px !important;
color: #fff;
margin: 0 !important;
padding: 2px !important;

}
.ico{
    position: absolute;
    left: 0px;
    top:0px;
    background-color: red;
    border-radius: 0px 0px 20px 0px;
    padding: 1px 2px;
    margin-left: 5px;
}
.banner__body{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 10px;
}
.sub__btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
}
.videoContent{
    width: 100%;
}
.progressbar{
width: 100%;

}
.prog__body{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    gap: 15px;
    color: #000;
    background-color: #fff;
    border: 1px solid rgba(117, 96, 255, 0.281);
    border-radius: 6px;
    padding: 8px 15px;
}
.progress__number{
    width: 50px;
    height: 40px;
    font-size: 17px;
    color: #3f71e6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: #f1efff;
}