.m-8fdc1311{
font-family: 'AlegreyaSans-Bold' !important;
    font-size: 20px;
}
.carousel.carousel-slider{
    margin: auto !important;
    width: 400px !important;
    height: 400px !important;
    
}
.css-exd1zr {
display: none !important;
}
.css-15wwp11-MuiTableHead-root { background-color: #3f72e7 !important; border-radius: 5px !important; -webkit-border-radius: 5px !important; -moz-border-radius: 5px !important; -ms-border-radius: 5px !important; -o-border-radius: 5px !important;
}
.css-1ndpvdd-MuiTableCell-root{
    color: #fff !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
font-size: 17px !important;
}
.searchbar {
    font-size: 14px;
    font-family: arial, sans-serif;
    color: #202124;
    display: flex;
    z-index: 3;
    height: 44px;
    background: white;
    border: 1px solid #dfe1e5;
    box-shadow: none;
    border-radius: 8px;
width: 50%;
  }
  
  .searchbar:hover {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223,225,229,0);
  }
  
  .searchbar-wrapper {
    flex: 1;
    display: flex;
    padding: 5px 8px 0 14px;
  }
  
  .searchbar-left {
    font-size: 14px;
    font-family: arial, sans-serif;
    color: #202124;
    display: flex;
    align-items: center;
    padding-right: 13px;
    margin-top: -5px;
  }
  
  .search-icon-wrapper {
    margin: auto;
  }
  
  .search-icon {
    margin-top: 3px;
    color: #9aa0a6;
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  
  .searchbar-icon {
    display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    position: relative;
    width: 24px;
  }
  
  .searchbar-center {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
  
  .searchbar-input-spacer {
    color: transparent;
    flex: 100%;
    white-space: pre;
    height: 34px;
    font-size: 16px;
  }
  
  .searchbar-input {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .87);
    word-wrap: break-word;
    outline: none;
    display: flex;
    flex: 100%;
    margin-top: -37px;
    height: 34px;
    font-size: 16px;
    max-width: 100%;
    width: 100%;
  }
  
  .searchbar-right {
    display: flex;
    flex: 0 0 auto;
    margin-top: -5px;
    align-items: stretch;
    flex-direction: row
  }
  
  .searchbar-clear-icon {
    margin-right: 12px
  }