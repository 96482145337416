.loginpage {
    width: fit-content;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff !important;
    padding:  30px!important;
    margin: 50px auto;
}

.signin{
        color: #333;
        font-size: 24px;
        font-weight: 800;
        font-family: 'AlegreyaSans-Bold' !important;
        text-align: center;
}
.full {
    width: 400px !important;
    padding: 10px;
}
.label{
    color: #BDBDBD;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.log__btn{
    --color: #fff;
    padding: 0.5em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 800;
    font-size: 17px;
    border: 1px solid #95b3fb;
    font-family: inherit;
    color: var(--color);
    z-index: 1;
    background-color: #95b3fb;
    text-decoration: none;
    width: 400px;
}

.log__btn::before,
.log__btn::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
}

.log__btn::before {
    top: -1em;
    left: -1em;
}

.log__btn::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.log__btn:hover::before,
.log__btn:hover::after {
    height: 450px;
    width: 450px;
}

.log__btn:hover {
    color: #000;
}

.log__btn:active {
    filter: brightness(.8);
}

.forget {
    color: #FF8201;
    text-decoration: none;
    text-transform: capitalize;

}
.forget__body{
margin-left: 150px !important;

 margin-top: 15px !important;
}
.otp input {

    outline: none;
    border-radius: 12px !important;
    width: 50px !important;
    transition: 300ms ease-in-out;
    font-size: 17px;
    color: #202020 !important;
    margin-top: 30px;
    height: 50px;
    margin: auto;
    direction: ltr !important;
    border-color: transparent !important;
    margin-top: 50px;
    border: 1px solid #eee !important;
    margin-left: 5px;
}
.forgetpass {
    width: 500px;
}

.forgetpass__body {
    padding-top: 70px !important;
}
.mob {
    display: none;
}

