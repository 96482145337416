.navbar {
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
font-family: 'AlegreyaSans-Regular';
    border-bottom: 1px solid #f7f7f767;
    background-color: #3f72e7;

}

.wrapper {
    width: 100%;
    padding: 5px 5px 0px 0px !important;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    position: fixed;
        background-color: #1C1A19;
        z-index: 99 !important;
}


.logo__body {
    padding-top: 10px;
    padding-left: 45px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}
body[dir='rtl'] .logo__body{
    padding-right: 45px;
}

.logo__title {
    text-align: center;
    margin-left: 20px ;
    padding-top: 12px;
    color: #FFF;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-decoration: none !important;
}
body[dir='rtl'] .logo__title{
    margin-right: -80px;
}
.items{
    padding-right: 45px;
}
.item {
    display: flex;
    align-items: center;
    margin-right: 0px;
    position: relative;
    column-gap: 0px;
}
.inputBox_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 380px;
    height: 50px;
    background-color:#fff;
    border-radius: 0.8em;
    overflow: hidden;
    margin-right: 10px;
}

.search_icon {
    height: 1em;
    padding: 0 0.5em 0 0.8em;
    fill: #333;
}

.inputBox {
    background-color: transparent;
    color: #777;
    outline: none;
    width: 100%;
    border: 0;
    padding: 0.5em 1.5em 0.5em 0;
    font-size: 15px;
}

::placeholder {
    color: var(--color-place);
}

.notification__body{
position: relative;
}
.noti{
    position: absolute;
    left: 23px;
    top:23px
}
.lang{
position: absolute;
    left: 17px;
    top: 23px
}
.lang span{
color: #FF8201;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 135%;
    margin-right: 2px;
}
body[dir='rtl'] .lang span{
    margin-left: 2px;
}
.white2{
    width: 70px;
}
.msg,
.white{
width: 70px;
}
.navlink {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.logo {
    width: 35%;
    margin-left: 5px !important;
}

.none {
    display: none;
}
.responsive{
    display: none;
    position: relative;
    z-index: 99999999999999999 !important;
}
.ul li{
    text-align: center;
    margin-bottom: 30px !important;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    color: #333;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
}
.li2{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width:0px) and (max-width:600px) {
.inputBox_container {
display: none;
}
.logo__body{
    padding-left: 10px;
}
.items{
    padding-right: 10px;
}
.notification__body{
    padding: 3px 5px;
}
.noti{
    width: 20px;
    top: 13px;
    left: 16px;
}
.lang{
    top: 13px;
    left: 15px;
}
.lang span{
    font-size: 10px;
}
.lang img{
    width: 10px !important;
}
.white2{
    width: 40px;
}
.msg,
.white{
    width: 40px;
}
.items{
    display: flex;
}
.responsive{
    display: block;
    cursor: pointer;
}
.menu__icon{
font-size: 40px !important;
    padding-top: 0px;
}
}
@media (min-width:600px) and (max-width:900px) {
    .inputBox_container{
        width: 100%;
    }
        .items {
            display: flex;
            padding-right: 10px;
        }
    .responsive{
        display:block !important;
        position: relative !important;
        z-index: 99999999999 !important;
    }
        .menu__icon {
            font-size: 55px !important;
            padding-top: 15px;
        }
}
.codelang{
    text-transform: capitalize;
}

@media (min-width:900px) and (max-width:1200px) {
    .responsive {
            display: block !important;
            position: relative !important;
            z-index: 99999999999 !important;
        }
                .items {
                    display: flex;
                    padding-right: 10px;
                }
                                .inputBox_container {
                                    width: 100%;
                                }
                                                                .menu__icon {
                                                                    font-size: 55px !important;
                                                                    padding-top: 15px;
                                                                }
}