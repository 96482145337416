.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
font-family: 'AlegreyaSans-Regular';
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.base__body{
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 15px;
}
.title,
.description{
    margin-top: 15px;
}
.color__body{
    display: flex;
    column-gap: 20px;
}
.colorknow{
    display: flex;
    column-gap: 5px;
    cursor: pointer;
    align-items: center;

}
.color__title{
font-family: 'AlegreyaSans-Bold';
font-size: 20px;
margin-bottom: 10px;
}
.color__para{
    font-size: 20px;
    font-family: 'AlegreyaSans-Regular';
}

.uploadimges,
.imgcolor{
    width: 100px;
    height: 100px;
    margin-left: 5px;
}
.select__para{
    font-family: 'AlegreyaSans-Regular';
    font-size: 20px;
    padding-bottom: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
}
/* .col1{
    border-right: 2px solid #eee;
    padding-right: 30px !important;
}
.col2{
    padding-left: 30px !important;
} */
.noimg__para{
    text-align: center;
    padding-top: 30px;
}
.zoom__img{
    width: 100%;
    height: 100%;
}
.carousel__image{
    text-align: center;
}
.fileImg {
    display: none;
}
.imggg{
    height: 100%;
    width: 100%;
}
.item__img{
    width: 70px;
    height: 70px;
}
.select{
padding: 8px;
    display: block;
    width: 100%;
    transition: border-color 100ms ease;
    text-align: var(--input-text-align);
    color: #000;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 5px;

}
.row{
width: 100% !important;
}
.oldnew_img{
    display: grid;
    grid-template-columns: repeat(3,auto);
    row-gap: 10px;
}
.sub__btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
}
.img{
    width: 100%;
    
}
.delete__body{
    position: relative;
}
.delete__icon{
width: 30px !important;
color: #fff;
margin: 0 !important;
padding: 2px !important;

}
.ico{
    position: absolute;
    left: 0px;
    top:0px;
    background-color: red;
    border-radius: 0px 0px 20px 0px;
    padding: 1px 2px;
    margin-left: 5px;
}
/*--------------------------------*/

.file__btn {
  width: 50%;
  height: 200px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #3f71e6;
  background-color: #fff;
  border: 1.5px dashed #3f71e6;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
}
.file__btn{
  width: 100% !important; 
  height: 100px;
}

.file__btn:hover {
  color: #3f71e6;
  background-color: #fff;
}


.file__btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: #3f71e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #f1efff;
}
.item__video{
    display: flex;
    flex-direction: column;
}
.progressbar{
    width: 100%;
    
    }
    .prog__body{
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        gap: 15px;
        color: #000;
        background-color: #fff;
        border: 1px solid rgba(117, 96, 255, 0.281);
        border-radius: 6px;
        padding: 8px 15px;
    }
    .progress__number{
        width: 50px;
        height: 40px;
        font-size: 17px;
        color: #3f71e6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background-color: #f1efff;
    }