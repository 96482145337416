body {
  margin: 0;
  font-family: 'Alegreya Sans SC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Alegreya Sans SC', sans-serif;
}

@font-face {
  font-family: 'AlegreyaSansSC-Regular';
  src: url(/src/assets/font/Alegreya_Sans_SC/AlegreyaSansSC-Regular.ttf);
}

@font-face {
  font-family: 'AlegreyaSansSC-Light';
  src: url(/src/assets/font/Alegreya_Sans_SC/AlegreyaSansSC-Light.ttf);
}

@font-face {
  font-family: 'AlegreyaSansSC-Bold';
  src: url(/src/assets/font/Alegreya_Sans_SC/AlegreyaSansSC-Bold.ttf);
}

@font-face {
  font-family: 'AlegreyaSans-Regular';
  src: url(/src/assets/font/Alegreya_Sans/AlegreyaSans-Regular.ttf);
}

@font-face {
  font-family: 'AlegreyaSans-Bold';
  src: url(/src/assets/font/Alegreya_Sans/AlegreyaSans-Bold.ttf);
}

a{
  text-decoration: none !important;
}
.file-btn,
.file-btn2 {
  width: 50%;
  height: 200px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #3f71e6;
  background-color: #fff;
  border: 1.5px dashed #3f71e6;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
}
.file-btn2{
  width: 100% !important; 
  height: 100px;
}
.file-btn:hover,
.file-btn2:hover {
  color: #3f71e6;
  background-color: #fff;
}

.file-btn span,
.file-btn2 span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: #3f71e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #f1efff;
}
.progress-bg {
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
}

.progress {
  width: 0%;
  height: 5px;
  background-color: #3f71e6;
  border-radius: 8px;
  transition: width 0.5s ease;
}
.upload-btn {
  width: 330px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #3f71e6;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.card {
  max-width: 320px;
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: rgba(96, 165, 250, 1);
  padding: 0.5rem;
  color: rgba(255, 255, 255, 1);
}

.icon svg {
  height: 1rem;
  width: 1rem;
}

.alert {
  font-weight: 600;
  color: rgba(107, 114, 128, 1);
  width: 250px !important;
}

.message {
  margin-top: 1rem;
  color: rgba(107, 114, 128, 1);
}

.actions {
  margin-top: 1.5rem;
}

.actions a {
  text-decoration: none;
}

.mark-as-read, .read {
  display: inline-block;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.read {
  background-color: rgba(59, 130, 246, 1);
  color: rgba(255, 255, 255, 1);
}

.mark-as-read {
  margin-top: 0.5rem;
  background-color: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  transition: all .15s ease;
}

.mark-as-read:hover {
  background-color: rgb(230, 231, 233);
}
