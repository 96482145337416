.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
font-family: 'AlegreyaSans-Regular';
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
}
.category__img{
    width: 70px;
    height: 70px;
}
.upload__para{
cursor: pointer;
}
.upload__para:hover{
    color: rgb(62, 115, 179);
}
.fileImg {
    display: none;
}
.img{
    width: 100%;
}
.selectcat_img{
    cursor: pointer;
    background-color: rgb(41, 78, 146);
    padding: 10px 15px;
    border-radius: 8px;
    width: fit-content;
    color: #fff;
}
.subcat__btn{
margin-top: 10px !important;
}
.sub__btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
}