.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
font-family: 'AlegreyaSans-Regular';
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.base__body{
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 15px;
}
.select{
        padding: 8px;
        display: block;
        width: 100%;
        transition: border-color 100ms ease;
        text-align: var(--input-text-align);
        color: #000;
        border: 1px solid #ced4da;
        background-color: #fff;
        border-radius: 5px;
        outline: none !important;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
}
    .imgcolor,
.img,
.uploadimges{
    width: 200px;
    height: 200px;
    margin-left: 5px;
}
.fileImg {
    display: none;
}
.imggg{
    height: 100%;
    width: 100%;
}
.text{
    font-family: 'AlegreyaSans-Bold' !important;
    font-size: calc(0.875rem* 1);

}
.show{
    border-radius: 5px;

}
.show__body{
    background-color: #fff;
    width: 330px;
    margin:  5px auto;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding-bottom: 100px;
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;

}
.noti__title{
    padding:  15px 15px 0px 15px;
    margin-bottom: 0px !important;
}
.card {
    width: 300px;
    border-radius: 0.5rem;
    background-color: #EEEEEC;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    margin: auto;
  }
  
  .card a {
    text-decoration: none
  }
  
  .content {
    padding: 1.1rem;
  }
  
  .image {
    object-fit: cover;
    width: 100%;
    height: 150px;
    background-color: rgb(239, 205, 255);
  }
  
  .title {
    color: #111827;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    display: flex !important;
  }
  
  .desc {
    margin-top: 0.5rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 150px !important;
  }
  
  .action {
    display: inline-flex;
    margin-top: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    align-items: center;
    gap: 0.25rem;
    background-color: #2563EB;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .action span {
    transition: .3s ease;
  }
  
  .action:hover span {
    transform: translateX(4px);
  }
  .sub__btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
  }
  .imgnotification{
    width: 100%;
  }
  .nopermision{
    display: flex;
    flex-direction: column;
    font-family: 'AlegreyaSans-Bold';
justify-content: center;
align-items: center;
  }
 
  .nopermision h2{
    font-size: 40px;
  }